// .ncellTos {
//   font-size: 13px;
//   margin-top: 20px;
// }

// .links-Section{
//   position: absolute;
//     top: 30%;
// }

// .gamezoneSignup
// {
//   background: rgb(238 90 17);
// }
@media(max-width: 280px){

  .games-info p 
  {
    font-size: 13px!important;

  }
  .games-info span
  {

    font-size: 12px!important;
  }
}