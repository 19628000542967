html[data-theme="orange-egypt"] {
    --primaryColor:#ff6600;
    --borderActiveTab : #ff6600;
    --activebgTaps : #ff6600;

    --titleColor : #ff6600;
    --secondaryBgColor: #12151d;
    --lightBgColor: #383b40;
    --darkBgColor: #1a1f2a;
    --homeBgColor:#1b1f2a;
    --appBg:#1b1f2a;
    --bgHeaderMenucolor:#ff6600;
    --activeColor:black;
    --borderActiveColor: #ff6600;
    --homeTabs: #27313b;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
    --mainTabsColor: #ffffff;
    --sidebardetailscolor: #ffffff;
    --LoveColor:#f4323d;
    --gameBgColor : #1b1f2a;
    --gameBorderradius : 0;
    --gameMargin : 0;
    --colorActive : #fff;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --gamename:#ffffff;
    --unSubscribeColor: #fff;

    --borderRadiusSize : 22px;
    --background-size: 'contain';
    --Allcolor: #ff6600;
    --gameDetailsType : rgb(120, 120, 120);
    --loginLogoWidth : 160px;
    --backgroundcat :#ff6600;
    --activeBackground: #ff6600;
    --imgBorder : 22%;
    --gameMarginTop : 0;
    --tabMargin :0;
    --gameBorderB :1px solid rgb(66, 69, 74);
    --gamePaddingB : 0;
    --linkGamemargin : 20px 3px 5px 15px;
    --navbarLogoWidth : 170px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/orangeegypt/OrangeLogo.png);
    --navbarLogo: url(../assets/images/logos/orangeegypt/OrangeLogo.png);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);

  }