html[data-theme="mtn"] {
    --primaryColor: #ffcb05;
    --activebgTaps : #1b1f2a;
    --borderActiveTab : #fff;
    --titleColor : #ffcb05;
    --activeColor:#ffcb05;
    --borderActiveColor: #ffcb05;
    --darkPrimaryColor: #004f6f;
    --secondaryBgColor: #ffcb05;
    --BgNavScroll : #ffcb05;
    --lightBgColor: #333742;
    --darkBgColor: #1b1f2a;
    --homeBgColor:#1b1f2a;
    --appBg:#1b1f2a;
    --textColor: #ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
    --mainTabsColor: #ffffff;
    --gameDetails : #ffffff;
    --sidebardetailscolor: #ffffff;
    --gamename:#ffffff;
    --unSubscribeColor: #fff;

    --borderRadiusSize : 22px;
    --homeTabs: #27313b;
    --LoveColor: #f4323d;
    --gameBgColor : #1b1f2a;
    --gameBorderradius : 0;
    --gameMargin : 0;
    --background-size: 160px;
    --loginLogoWidth : 160px;
    --navbarLogoWidth : 160px;
    --navSearchIconheight : 17px;
      --navSearchIconwidth : 17px;
      --navMenuIconheight : 20px;
      --navMenuIconwidth : 25px;
    --gameDetailsType : rgb(120, 120, 120);
    --Allcolor: #797b7e;
    --backgroundcat :#1a1f2a;
    --activeBackground: #1a1f2a;
    --bgHeaderMenucolor:#1a1f2a;
    --linkGamemargin : 20px 3px 5px 15px;
    --imgBorder : 22%;
    --tabMargin :0;
    --gameBorderB :1px solid rgb(66, 69, 74);
    --gameMarginTop : 0;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --rankingHeader : 211px;
    --rankingHeadercontent : 200;
    --colorActive : #fff;
    --paddingTaps : 0;
    --marginScroll: 0 0;
    --opacityScroll : 'none';
    --profileTextColor : #fff;
    --loginLogoHeight : 180px;
    --loginLogo: url(../assets/images/logos/yemen/mtnYESignInLogo.svg);
    --navbarLogo: url(../assets/images/logos/yemen/mtnYEHomeLogo.svg);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --paymentLogo : url(../assets/images/logos/default/fawry_logo.svg);
    --play : url(../assets/images/logos/gamezroid/play.png);
    --exclusiveGame : url(../assets/images/background/EX.png);
    --exclusiveGamePart2 : url(../assets/images/background/EX.png);
    --competitionBg : url(../assets/images/competetion-new.jpg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);
    --insideBg:'';
    --loginBg:'';
  }