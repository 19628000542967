html[data-theme="ooredoops"] {
    --primaryColor:#ED1C24;
    --borderActiveTab : #ED1C24;
    --activebgTaps : #ED1C24;

    --titleColor : #ED1C24;
    --secondaryBgColor: #000;
    --lightBgColor: #383b40;
    --darkBgColor: #1b1f2a;
    --homeBgColor:#ffffff;
    --appBg:#fff;

    --bgHeaderMenucolor:#ED1C24;
    --activeColor:#1b1f2a;
    --borderActiveColor: #1b1f2a;
    --homeTabs: #27313b;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : black;
    --mainTabsColor: #ffffff;
    --sidebardetailscolor: #000;
    --LoveColor:#f4323d;
    --gamename:#000;
    --unSubscribeColor: #000;

    --gameBgColor : #ffffff;
    --gameMargin : 0;
    --colorActive : #fff;
    --profileTextColor : #000;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --gameBorderradius : 0;
    --borderRadiusSize : 22px;
    --background-size: 'contain';
    --Allcolor: #ED1C24;
    --imgBorder : 22%;
    --tabMargin :0;
    --gameBorderB :'none';
    --gamePaddingB : 0;
    --gameMarginTop : 0;
    --backgroundcat :#ED1C24;
    --activeBackground: #ED1C24;
    --gameDetails : #787878;
    --gameDetailsType : #787878;
    --loginLogoWidth : 350px;
    --navbarLogoWidth : 240px;
    --linkGamemargin : 20px 3px 5px 15px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/ooredoo/Oredoopslogonav.png);
    --navbarLogo: url(../assets/images/logos/ooredoo/Oredoopslogonav.png);
    --leaderboard : url(../assets/images/leaderboard/bg-skwuit.svg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);
    --EnfontFamily : 'Rubik-Light';
    --EnHeadlinefontFamily: 'Outfit-Thin';

    --ArfontFamily : 'Noto-Kufi-Arabic';
    --ArHeadlinefontFamily: 'Noto-Kufi-Arabic';



  }