html[data-theme="expresso-senegal"] {
  --primaryColor: #8ce6e6;
  --borderActiveTab : #8ce6e6;
  --activebgTaps : #8ce6e6;

  --titleColor : #8ce6e6;
  --activeColor:#8ce6e6;
  --borderActiveColor: #8ce6e6;
  --secondaryBgColor: #12151d;
  --BgNavScroll : #12151d;

  --lightBgColor: #383b40;
  --darkBgColor: #1a1f2a;
  --homeBgColor:#1b1f2a;
  --appBg:#1b1f2a;

  --bgHeaderMenucolor:#1a1f2a;
  --homeTabs: #27313b;
  --textColor: #ffffff;
  --subBorderColor:#fff;
  --paymentTextcolor : #ffffff;
  --mainTabsColor: #ffffff;
  --LoveColor: #f4323d;
  --background-size: 160px;
  --sidebardetailscolor: #ffffff;
  --Allcolor: #ffffff;
  --colorActive : #fff;
  --profileTextColor : #fff;
  --navSearchIconheight : 17px;
  --navSearchIconwidth : 17px;
  --navMenuIconheight : 20px;
  --navMenuIconwidth : 25px;

  --gameMargin : 0;
  --gameBgColor : #1b1f2a;
  --gameBorderradius : 0;
  --borderRadiusSize : 22px;
  --loginLogoWidth : 160px;
  --navbarLogoWidth : 160px;
  --tabMargin :0;
  --gameMarginTop : 0;
  --gameBorderB :'none';
  --gamePaddingB : 0;
  --linkGamemargin : 20px 3px 5px 15px;
  --gameDetailsType : rgb(120, 120, 120);
  --gameMargin : 0;
  --gamename:#ffffff;
  --unSubscribeColor: #fff;

  --backgroundcat :#1a1f2a;
  --activeBackground: #1a1f2a;
  --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
  --loginLogo: url(../assets/images/logos/senegal/expresso-sn.svg);
  --navbarLogo: url(../assets/images/logos/senegal/expresso-sn.svg);
  --leaderboard : url(../assets/images/leaderboard/bg.svg);
  --menuBg:url(../assets/icons/burger-menu.svg);
  --searchBg:url(../assets/icons/search.svg);

}
