.playing {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: var(--darkBgColor); }

.PlayingComp {
  width: 100%;
  height: 0;
  /* margin-bottom: 39%; */
  background-color: #1b1f2a;
  width: 100%;
  /* height: 100vh; */
  /* min-height: 100vh; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000; }

#game-frame {
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height: 1px;
  min-height: 100%;
  *height: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  overflow-y: auto;
  overflow-x: hidden; }

body[dir="rtl"] .gameFooter {
  flex-direction: row-reverse; }

body[dir="rtl"] .gameData .titleDetails .title, body[dir="rtl"] .describe {
  text-align: right; }

.iframe-container {
  height: 95vh;
  min-width: 100%;
  width: 100px;
  *width: 100%;
  /*position: absolute;
  top: 0;
  z-index: 99;*/ }

.compiframe-container {
  height: 100% !important;
  position: absolute;
  position: a;
  top: 0;
  left: 0;
  z-index: 99; }

.scoretext {
  height: 100%;
  background: url("../../assets/images/score.png") no-repeat;
  background-size: contain;
  background-position: center; }

.scoretext p {
  position: absolute;
  top: 63%;
  left: 46.5%;
  font-size: 17px;
  /* position: absolute; */
  /* top: 68%; */
  /* left: 47%; */
  /* font-size: 17px; */ }

.scoretext p span {
  color: #d42828;
  font-size: 18px; }

.iframe-container iframe {
  min-width: 100%;
  width: 100px;
  *width: 100%;
  min-height: 100%;
  height: 100px;
  *height: 100%;
  /* height: 100%; */ }

.iframe-container object {
  min-width: 100%;
  width: 100px;
  *width: 100%;
  min-height: 100%;
  height: 100px;
  *height: 100%;
  /* height: 100%; */ }

.thumb_images {
  max-width: 100%; }

.thumb_images img {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  margin: 5px;
  outline: none !important; }

.gameBody {
  padding: 5px 25px 20px !important; }

.desc {
  height: auto;
  transition: width 2s;
  transition-duration: 2s;
  transition: all 1s ease; }

.descLess {
  height: 25px;
  overflow: hidden;
  margin-bottom: 10px;
  line-height: 1.5;
  opacity: 0.75;
  font-size: 14px;
  padding-bottom: 14px;
  transition: width 2s;
  transition: all 1s ease; }

.full {
  background: url("../../assets/images/fullscreen.png") no-repeat center center;
  width: 50px;
  height: 50px;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  z-index: 10;
  outline: none; }

.seeP {
  text-align: left;
  line-height: 1.5;
  font-size: 14px;
  padding-bottom: 14px;
  margin: 0;
  color: #009edd;
  transition: all 1s ease; }

@media (max-width: 768px) {
  .scoretext p {
    top: 66%;
    left: 44%;
    font-size: 20px; } }

@media (max-width: 540px) {
  .scoretext p {
    top: 63%;
    left: 42%; } }

@media (max-width: 418px) {
  .scoretext p {
    top: 65%;
    left: 39%; } }

@media (max-width: 414px) {
  .scoretext p {
    top: 63%; } }

@media (max-width: 400px) {
  .scoretext p {
    top: 61%; } }

@media (max-width: 375px) {
  .scoretext p {
    top: 63%; } }

@media (max-width: 360px) {
  .scoretext p {
    top: 61%;
    left: 38%;
    font-size: 20px; } }

@media (max-width: 320px) {
  .scoretext p {
    top: 61%;
    left: 37%;
    font-size: 17px; } }

@media (max-width: 280px) {
  .scoretext p {
    top: 63%;
    left: 35%; } }
