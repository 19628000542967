.burgerMenu{
    background: var(--menuBg) center center no-repeat;
    height: var(--navMenuIconheight);
    width: var(--navMenuIconwidth)
}
.logo{
    background: var(--navbarLogo) center center no-repeat;
    //height: 26px;
    height: 50px;
    width: var(--navbarLogoWidth);
}
.search{
    background: var(--searchBg) center center no-repeat;
    height: var(--navSearchIconheight);
    width: var(--navSearchIconwidth)
}


