.containerComp {
  background: var(--homepageBgCompetition);
  background-size: cover; }

.compbuttons {
  display: flex;
  flex-direction: row;
  border: 1px solid red; }

.compbuttons > div {
  flex: 50%;
  border: 1px solid green; }

.leaderboard {
  background: var(--leaderboard-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: left; }

.tos {
  background: var(--help-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: right; }

.compbtn button {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: none;
  outline: none; }

.moreGame {
  width: 260px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  background-color: transparent; }

.compHomeGames {
  width: 56% !important;
  margin-top: 2px !important;
  flex-wrap: wrap; }

.btnspage {
  min-height: 85px;
  width: 56%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.btnhomeramdan {
  background: url("../../assets/images/HomepagecomGamezroid/homebtbg.png") no-repeat center center;
  background-size: contain;
  color: var(--textColor);
  padding: 10px;
  height: 69px;
  line-height: 9px;
  width: 110px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-family: SourceSansPro-Bold;
  transition: all ease 0.4s; }

.btnhomeramdan p {
  margin-top: 11px; }

.imgGame img {
  width: 100px;
  height: 100px; }

.gameCompName {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  padding: 2px 7px 9px 7px;
  text-transform: uppercase; }

.coins {
  width: 100px;
  height: 75px;
  position: absolute;
  top: 22%;
  left: 24%; }

body[dir='rtl'] .btnhomeramdan {
  font-size: 20px; }

@media (max-width: 1024px) {
  .compHomeGames {
    width: 61% !important; }
  .coins {
    left: -26px; } }

@media (max-width: 950px) {
  .compHomeGames {
    width: 30% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; } }

@media (max-width: 768px) {
  .btnspage {
    width: 62%; }
  .compbtn {
    width: 65% !important; }
  .spaceTop {
    height: 3vh !important; }
  .compHomeGames {
    width: 65% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; }
  .imgGame img {
    width: 90px;
    height: 90px; }
  .section-home {
    width: 72% !important; } }

@media (max-width: 540px) {
  .btnspage {
    width: 75%; }
  .containerComp {
    height: 719px !important;
    background-size: cover; }
  .compHomeGames {
    width: 77% !important; }
  .spaceTop {
    height: 7vh !important; }
  .section-home {
    width: 86% !important; }
  .compbtn {
    width: 80% !important; } }

@media (max-width: 418px) {
  .btnspage {
    width: 100%; }
  .containerComp {
    min-height: 112vh;
    background-size: cover; }
  .compbtn {
    width: 100% !important; }
  .spaceTop {
    height: 6vh !important; } }

@media (max-width: 418px) {
  .containerComp {
    height: 873px !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; } }

@media (max-width: 411px) {
  .spaceTop {
    height: 2vh !important; }
  .containerComp {
    background-size: cover; } }

@media (max-width: 400px) {
  .containerComp {
    height: 838px !important;
    background-size: cover; } }

@media (max-width: 376px) {
  .containerComp {
    height: 814px !important;
    min-height: 108vh !important;
    background-size: cover; }
  .coins {
    top: 102px; } }

@media (max-width: 360px) {
  .spaceTop {
    height: 5vh !important; }
  .coins {
    top: 120px; } }

@media (max-width: 320px) {
  .links-SectionCompetition {
    width: 98% !important; }
  .containerComp {
    height: 567px !important;
    min-height: 130vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .imgGame img {
    width: 75px;
    height: 75px; }
  .coins {
    top: 100px;
    left: -3px; }
  .btnhomeramdan {
    width: 100px; }
  .headerCompetion {
    margin-top: 0 !important; } }

@media (max-width: 280px) {
  .containerComp {
    height: 651px !important;
    min-height: 114vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .links-SectionCompetition {
    width: 100% !important; }
  .logoComp {
    width: 130px !important; } }

@media (max-device-width: 1024px) and (orientation: landscape) {
  .containerComp {
    min-height: 109vh !important; } }

@media (max-device-width: 993px) and (orientation: landscape) {
  .containerComp {
    min-height: 170vh !important; } }

@media (max-device-width: 882px) and (orientation: landscape) {
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 823px) and (orientation: landscape) {
  .containerComp {
    min-height: 172vh !important; } }

@media (max-device-width: 768px) and (orientation: landscape) {
  .containerComp {
    min-height: 184vh !important; } }

@media (max-device-width: 640px) and (orientation: landscape) {
  .spaceTop {
    height: 4vh !important; }
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 653px) and (orientation: landscape) {
  .spaceTop {
    height: 0 !important; }
  /*.containerComp {
        min-height: 226vh !important;
    }*/ }

.ice {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 200;
  overflow: hidden;
  pointer-events: none; }

.g-snows {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0; }

.g-snows > li {
  opacity: 0;
  position: absolute;
  top: 0;
  border-radius: 100%;
  background: url("../../assets/images/competition/partical1.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  animation-name: snow-drop;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards; }
  .g-snows > li:nth-child(1) {
    left: 19%;
    width: 17px;
    height: 17px;
    animation-duration: 14595ms;
    animation-delay: 2419ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(2) {
    left: 11%;
    width: 1px;
    height: 1px;
    animation-duration: 11426ms;
    animation-delay: 933ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(3) {
    left: 58%;
    width: 21px;
    height: 21px;
    animation-duration: 5885ms;
    animation-delay: 2487ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(4) {
    left: 22%;
    width: 24px;
    height: 24px;
    animation-duration: 5748ms;
    animation-delay: 1516ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(5) {
    left: 145%;
    width: 8px;
    height: 8px;
    animation-duration: 6606ms;
    animation-delay: 4596ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(6) {
    left: 45%;
    width: 16px;
    height: 16px;
    animation-duration: 6181ms;
    animation-delay: 2317ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(7) {
    left: 79%;
    width: 5px;
    height: 5px;
    animation-duration: 9225ms;
    animation-delay: 3293ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(8) {
    left: 22%;
    width: 5px;
    height: 5px;
    animation-duration: 8346ms;
    animation-delay: 103ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(9) {
    left: 86%;
    width: 6px;
    height: 6px;
    animation-duration: 10759ms;
    animation-delay: 718ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(10) {
    left: 55%;
    width: 22px;
    height: 22px;
    animation-duration: 8254ms;
    animation-delay: 1643ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(11) {
    left: 46%;
    width: 18px;
    height: 18px;
    animation-duration: 10972ms;
    animation-delay: 1862ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(12) {
    left: 81%;
    width: 7px;
    height: 7px;
    animation-duration: 6742ms;
    animation-delay: 541ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(13) {
    left: 46%;
    width: 10px;
    height: 10px;
    animation-duration: 8017ms;
    animation-delay: 1903ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(14) {
    left: 19%;
    width: 12px;
    height: 12px;
    animation-duration: 5973ms;
    animation-delay: 956ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(15) {
    left: 24%;
    width: 17px;
    height: 17px;
    animation-duration: 6128ms;
    animation-delay: 896ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(16) {
    left: 26%;
    width: 7px;
    height: 7px;
    animation-duration: 5502ms;
    animation-delay: 1054ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(17) {
    left: 70%;
    width: 23px;
    height: 23px;
    animation-duration: 6766ms;
    animation-delay: 3859ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(18) {
    left: 107%;
    width: 3px;
    height: 3px;
    animation-duration: 9901ms;
    animation-delay: 62ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(19) {
    left: 11%;
    width: 15px;
    height: 15px;
    animation-duration: 5007ms;
    animation-delay: 880ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(20) {
    left: 140%;
    width: 16px;
    height: 16px;
    animation-duration: 8356ms;
    animation-delay: 3427ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(21) {
    left: 22%;
    width: 22px;
    height: 22px;
    animation-duration: 10654ms;
    animation-delay: 2048ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(22) {
    left: 14%;
    width: 9px;
    height: 9px;
    animation-duration: 9330ms;
    animation-delay: 2711ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(23) {
    left: 83%;
    width: 1px;
    height: 1px;
    animation-duration: 9536ms;
    animation-delay: 2795ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(24) {
    left: 76%;
    width: 14px;
    height: 14px;
    animation-duration: 8179ms;
    animation-delay: 4127ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(25) {
    left: 31%;
    width: 4px;
    height: 4px;
    animation-duration: 8987ms;
    animation-delay: 2001ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(26) {
    left: 82%;
    width: 2px;
    height: 2px;
    animation-duration: 8688ms;
    animation-delay: 224ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(27) {
    left: 43%;
    width: 12px;
    height: 12px;
    animation-duration: 5741ms;
    animation-delay: 1692ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(28) {
    left: 103%;
    width: 15px;
    height: 15px;
    animation-duration: 8185ms;
    animation-delay: 3196ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(29) {
    left: 35%;
    width: 14px;
    height: 14px;
    animation-duration: 8474ms;
    animation-delay: 4435ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(30) {
    left: 144%;
    width: 17px;
    height: 17px;
    animation-duration: 5169ms;
    animation-delay: 4733ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(31) {
    left: 116%;
    width: 26px;
    height: 26px;
    animation-duration: 7167ms;
    animation-delay: 2910ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(32) {
    left: 52%;
    width: 24px;
    height: 24px;
    animation-duration: 9279ms;
    animation-delay: 1268ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(33) {
    left: 2%;
    width: 6px;
    height: 6px;
    animation-duration: 6643ms;
    animation-delay: 2106ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(34) {
    left: 19%;
    width: 1px;
    height: 1px;
    animation-duration: 8151ms;
    animation-delay: 3935ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(35) {
    left: 28%;
    width: 22px;
    height: 22px;
    animation-duration: 5318ms;
    animation-delay: 1668ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(36) {
    left: 100%;
    width: 4px;
    height: 4px;
    animation-duration: 10947ms;
    animation-delay: 3176ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(37) {
    left: 5%;
    width: 16px;
    height: 16px;
    animation-duration: 5918ms;
    animation-delay: 3930ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(38) {
    left: 54%;
    width: 3px;
    height: 3px;
    animation-duration: 8957ms;
    animation-delay: 3378ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(39) {
    left: 41%;
    width: 11px;
    height: 11px;
    animation-duration: 10866ms;
    animation-delay: 772ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(40) {
    left: 46%;
    width: 8px;
    height: 8px;
    animation-duration: 6508ms;
    animation-delay: 147ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(41) {
    left: 70%;
    width: 1px;
    height: 1px;
    animation-duration: 7763ms;
    animation-delay: 4072ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(42) {
    left: 65%;
    width: 9px;
    height: 9px;
    animation-duration: 5608ms;
    animation-delay: 3871ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(43) {
    left: 5%;
    width: 6px;
    height: 6px;
    animation-duration: 10183ms;
    animation-delay: 2615ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(44) {
    left: 43%;
    width: 15px;
    height: 15px;
    animation-duration: 6030ms;
    animation-delay: 3508ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(45) {
    left: 36%;
    width: 10px;
    height: 10px;
    animation-duration: 5189ms;
    animation-delay: 2932ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(46) {
    left: 48%;
    width: 10px;
    height: 10px;
    animation-duration: 11182ms;
    animation-delay: 386ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(47) {
    left: 12%;
    width: 0px;
    height: 0px;
    animation-duration: 10659ms;
    animation-delay: 2057ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(48) {
    left: 97%;
    width: 18px;
    height: 18px;
    animation-duration: 7691ms;
    animation-delay: 2611ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(49) {
    left: 12%;
    width: 4px;
    height: 4px;
    animation-duration: 8829ms;
    animation-delay: 713ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(50) {
    left: 49%;
    width: 22px;
    height: 22px;
    animation-duration: 6468ms;
    animation-delay: 529ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(51) {
    left: 65%;
    width: 12px;
    height: 12px;
    animation-duration: 7534ms;
    animation-delay: 4560ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(52) {
    left: 39%;
    width: 17px;
    height: 17px;
    animation-duration: 6736ms;
    animation-delay: 2467ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(53) {
    left: 111%;
    width: 3px;
    height: 3px;
    animation-duration: 9089ms;
    animation-delay: 2446ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(54) {
    left: 24%;
    width: 3px;
    height: 3px;
    animation-duration: 9606ms;
    animation-delay: 4366ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(55) {
    left: 8%;
    width: 16px;
    height: 16px;
    animation-duration: 9431ms;
    animation-delay: 2445ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(56) {
    left: 63%;
    width: 15px;
    height: 15px;
    animation-duration: 6781ms;
    animation-delay: 1226ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(57) {
    left: 117%;
    width: 18px;
    height: 18px;
    animation-duration: 10197ms;
    animation-delay: 1257ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(58) {
    left: 21%;
    width: 2px;
    height: 2px;
    animation-duration: 12161ms;
    animation-delay: 1573ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(59) {
    left: 43%;
    width: 5px;
    height: 5px;
    animation-duration: 9657ms;
    animation-delay: 1183ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(60) {
    left: 126%;
    width: 0px;
    height: 0px;
    animation-duration: 12714ms;
    animation-delay: 777ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(61) {
    left: 2%;
    width: 16px;
    height: 16px;
    animation-duration: 10229ms;
    animation-delay: 1278ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(62) {
    left: 52%;
    width: 23px;
    height: 23px;
    animation-duration: 8368ms;
    animation-delay: 4999ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(63) {
    left: 76%;
    width: 9px;
    height: 9px;
    animation-duration: 12701ms;
    animation-delay: 1403ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(64) {
    left: 21%;
    width: 5px;
    height: 5px;
    animation-duration: 10118ms;
    animation-delay: 4046ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(65) {
    left: 27%;
    width: 21px;
    height: 21px;
    animation-duration: 5145ms;
    animation-delay: 1322ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(66) {
    left: 34%;
    width: 3px;
    height: 3px;
    animation-duration: 7888ms;
    animation-delay: 2777ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(67) {
    left: 10%;
    width: 6px;
    height: 6px;
    animation-duration: 6761ms;
    animation-delay: 3277ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(68) {
    left: 26%;
    width: 16px;
    height: 16px;
    animation-duration: 11027ms;
    animation-delay: 3227ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(69) {
    left: 18%;
    width: 21px;
    height: 21px;
    animation-duration: 9417ms;
    animation-delay: 1435ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(70) {
    left: 15%;
    width: 16px;
    height: 16px;
    animation-duration: 11650ms;
    animation-delay: 1735ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(71) {
    left: 44%;
    width: 17px;
    height: 17px;
    animation-duration: 5087ms;
    animation-delay: 3062ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(72) {
    left: 19%;
    width: 3px;
    height: 3px;
    animation-duration: 9039ms;
    animation-delay: 3784ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(73) {
    left: 40%;
    width: 9px;
    height: 9px;
    animation-duration: 12095ms;
    animation-delay: 3237ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(74) {
    left: 110%;
    width: 12px;
    height: 12px;
    animation-duration: 10219ms;
    animation-delay: 4678ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(75) {
    left: 13%;
    width: 5px;
    height: 5px;
    animation-duration: 6597ms;
    animation-delay: 2716ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(76) {
    left: 12%;
    width: 2px;
    height: 2px;
    animation-duration: 7945ms;
    animation-delay: 602ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(77) {
    left: 11%;
    width: 4px;
    height: 4px;
    animation-duration: 6594ms;
    animation-delay: 177ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(78) {
    left: 33%;
    width: 5px;
    height: 5px;
    animation-duration: 11828ms;
    animation-delay: 772ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(79) {
    left: 4%;
    width: 13px;
    height: 13px;
    animation-duration: 5891ms;
    animation-delay: 174ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(80) {
    left: 5%;
    width: 3px;
    height: 3px;
    animation-duration: 7923ms;
    animation-delay: 3492ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(81) {
    left: 66%;
    width: 6px;
    height: 6px;
    animation-duration: 9394ms;
    animation-delay: 895ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(82) {
    left: 38%;
    width: 6px;
    height: 6px;
    animation-duration: 6659ms;
    animation-delay: 2129ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(83) {
    left: 41%;
    width: 3px;
    height: 3px;
    animation-duration: 9368ms;
    animation-delay: 4417ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(84) {
    left: 37%;
    width: 10px;
    height: 10px;
    animation-duration: 8590ms;
    animation-delay: 2961ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(85) {
    left: 26%;
    width: 15px;
    height: 15px;
    animation-duration: 7071ms;
    animation-delay: 1936ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(86) {
    left: 98%;
    width: 1px;
    height: 1px;
    animation-duration: 9273ms;
    animation-delay: 679ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(87) {
    left: 39%;
    width: 2px;
    height: 2px;
    animation-duration: 10955ms;
    animation-delay: 3749ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(88) {
    left: 20%;
    width: 24px;
    height: 24px;
    animation-duration: 11502ms;
    animation-delay: 2308ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(89) {
    left: 59%;
    width: 4px;
    height: 4px;
    animation-duration: 7416ms;
    animation-delay: 1744ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(90) {
    left: 8%;
    width: 22px;
    height: 22px;
    animation-duration: 8969ms;
    animation-delay: 2823ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(91) {
    left: 9%;
    width: 3px;
    height: 3px;
    animation-duration: 5509ms;
    animation-delay: 2354ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(92) {
    left: 33%;
    width: 17px;
    height: 17px;
    animation-duration: 10329ms;
    animation-delay: 1845ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(93) {
    left: 74%;
    width: 4px;
    height: 4px;
    animation-duration: 10722ms;
    animation-delay: 3422ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(94) {
    left: 27%;
    width: 7px;
    height: 7px;
    animation-duration: 6509ms;
    animation-delay: 3598ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(95) {
    left: 78%;
    width: 11px;
    height: 11px;
    animation-duration: 7827ms;
    animation-delay: 912ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(96) {
    left: 32%;
    width: 23px;
    height: 23px;
    animation-duration: 5321ms;
    animation-delay: 589ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(97) {
    left: 37%;
    width: 14px;
    height: 14px;
    animation-duration: 12084ms;
    animation-delay: 3356ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(98) {
    left: 41%;
    width: 21px;
    height: 21px;
    animation-duration: 9370ms;
    animation-delay: 4992ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(99) {
    left: 18%;
    width: 1px;
    height: 1px;
    animation-duration: 10073ms;
    animation-delay: 4241ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(100) {
    left: 126%;
    width: 12px;
    height: 12px;
    animation-duration: 7686ms;
    animation-delay: 3278ms;
    filter: blur(0px); }

@keyframes snow-drop {
  0% {
    transform: translate(0, 0);
    opacity: 0.5;
    margin-left: 0; }
  10% {
    margin-left: 15px; }
  20% {
    margin-left: 20px; }
  25% {
    transform: translate(0, 166.66667px);
    opacity: 0.75; }
  30% {
    margin-left: 15px; }
  40% {
    margin-left: 0; }
  50% {
    transform: translate(0, 333.33333px);
    opacity: 1;
    margin-left: -15px; }
  60% {
    margin-left: -20px; }
  70% {
    margin-left: -15px; }
  75% {
    transform: translate(0, 500px);
    opacity: 0.5; }
  80% {
    margin-left: 0; }
  100% {
    transform: translate(0, 666.66667px);
    opacity: 0; } }
