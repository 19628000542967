// .ncellTos {
//   font-size: 13px;
//   margin-top: 20px;
// }

// .links-Section{
//   position: absolute;
//     top: 30%;
//     border: 1px solid red;
// }

// .gamezoneSignup
// {
//   background: rgb(238 90 17);
// }

// .mondiacontent
// {

//   color: #f8e250;
//   width: 90%;
//   text-align: center;
//   line-height: 1.2em;
//   font-size: 1.12em;
//   font-weight: 900;
//   padding: .48em .8em;
//   //font-family: duCo_Headline16_iOS_Lt;
// }

.jawwalgamezoridncellTos p
{    line-height: 20px;
 // background: url("../../assets/images/mondia/freegame.png");
}

.jawwalgamezoridFooter
{
  margin-bottom: 0!important;

}

.mondiaverifyoperator
{
  background : url("../../assets/images/mondia/verify.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.Permission
{
  padding: 6px 58px;
  border: 1px solid silver;
  border-radius: 6px;
}
.Permission p{
  color: #cdcece;
    text-align: center;
    //font-family: Metrostyle_Extended!important;
}
@media(max-width: 1024px){
  .links-SectionMondia
  {
    width: 100%!important;

  }
//   .mondiaverifyoperator
// {
//   background-position: initial;
// }

// .formsLayoutmondiaverify
// {
//   min-height: 65vh!important;
// }
}


@media(max-width: 540px){
    .formsLayout .jawwalgamezoridFooter {
       margin-bottom: 10px!important; 
  }
  .jawwalgamezoridncellTos p 
  {
    //margin: 8px;
  }

  .mondiaverifyoperator
  {
    background-position: initial;
  }
  
  // .formsLayoutmondiaverify
  // {
  //   min-height: 65vh!important;
  // }

 
  
  .links-Sectionjawwalgamezorid button{
    margin: 20px auto!important;
  }
    
  
  }

  @media(max-width: 418px){
    //   .formsLayout .jawwalgamezoridFooter {
    //      margin-bottom: 0!important; 
    // }
    // .jawwalgamezoridncellTos p 
    // {
    //   line-height: 16px;
    // }
    
    .links-Sectionjawwalgamezorid button{
      margin: 40px auto!important;
    }
      
    
    }

    @media(max-width: 414px){
      //   .formsLayout .jawwalgamezoridFooter {
      //      margin-bottom: 0!important; 
      // }
      .jawwalgamezoridncellTos p 
      {
        line-height: 16px;
      }
      
      .links-Sectionjawwalgamezorid button{
        margin: 16px auto!important;
      }
        
      
      }

@media(max-width: 411px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0!important; 
}
.jawwalgamezoridncellTos p 
{
  //margin: 10px;
}

.links-Sectionjawwalgamezorid button{
  margin: 40px auto!important;
}
  

}





@media(max-width: 375px){
  .formsLayout .jawwalgamezoridFooter {
    // margin-bottom: 70px!important; 
}
.jawwalgamezoridncellTos p 
{
  line-height: 16px;
}

.links-Sectionjawwalgamezorid button{
  margin: 25px auto!important;
}
  

}



@media(max-width: 360px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0!important; 
}
.jawwalgamezoridncellTos p 
{
  margin: 8px;
  font-size: 10px!important;
  line-height: 15px;
}

.links-Sectionjawwalgamezorid button{
  margin: 20px auto!important;
}
  

}

@media(max-width: 320px){
  .formsLayout .jawwalgamezoridFooter {
     margin-bottom: 0!important; 
}
.jawwalgamezoridncellTos p 
{
  margin: 0px;
  font-size: 10px!important;
}

.links-Sectionjawwalgamezorid button{
  margin: 10px auto!important;
}

.jawwalgames-info p 
{
  font-size: 12px;
}
  

}