

html[data-theme="korekiq"] {
    --primaryColor:#1f4e79;
    --borderActiveTab : #1f4e79;
    --activebgTaps : #1f4e79;

    --titleColor : #383b40;
    --secondaryBgColor: #12151d;
    --BgNavScroll : #12151d;

    --lightBgColor: #383b40;
    --darkBgColor: #1a1f2a;
    --homeBgColor:#ffffff;
    --appBg:#ffffff;
    --bgHeaderMenucolor:#1f4e79;
    --activeColor:black;
    --borderActiveColor: #1f4e79;
    --homeTabs: #27313b;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : black;
    --mainTabsColor: #ffffff;
    --sidebardetailscolor: black;
    --LoveColor:#f4323d;
    --gameBgColor : #ffffff;
    --gameBorderradius : 0;
    --gameMargin : 0;
    --colorActive : #fff;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --gamename:#12151d;
    --unSubscribeColor: #12151d;

    --borderRadiusSize : 22px;
    --background-size: 'contain';
    --Allcolor: #1f4e79;
    --gameDetails : #0f0e0e;
    --gameDetailsType : rgb(120, 120, 120);
    --loginLogoWidth : 350px;
    --backgroundcat :#1f4e79;
    --activeBackground: #1f4e79;
    --imgBorder : 22%;
    --gameMarginTop : 0;
    --tabMargin :0;
    --gameBorderB :1px solid rgb(66, 69, 74);
    --gamePaddingB : 0;
    --linkGamemargin : 20px 3px 5px 15px;
    --navbarLogoWidth : 120px;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    --loginLogo: url(../assets/images/logos/default/playit-with-text.svg);
    --navbarLogo: url(../assets/images/logos/default/playit.svg);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --paymentLogo : url(../assets/images/logos/default/fawry_logo.svg);
    --play : url(../assets/images/logos/gamezroid/play.png);
    --exclusiveGame : url(../assets/images/background/EX.png);
    --exclusiveGamePart2 : url(../assets/images/background/EX.png);
    --competitionBg : url(../assets/images/competetion-new.jpg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);
    --insideBg:'';
    --loginBg:'';

  }