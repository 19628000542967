html[data-theme="zain-sudan"] {
    --primaryColor: #0bb5a7;
    --activebgTaps : #009edd;

    --borderActiveTab : #0bb5a7;
    --titleColor : #0bb5a7;
    --activeColor:#0bb5a7;
    --borderActiveColor: #0bb5a7;
    --secondaryBgColor: #12151d;
    --lightBgColor: #383b40;
    --darkBgColor: #1a1f2a;
    --homeBgColor:#1b1f2a;
    --appBg:#1b1f2a;
    --bgHeaderMenucolor:#1a1f2a;
    --homeTabs: #27313b;
    --textColor:#ffffff;
    --subBorderColor:#fff;
    --paymentTextcolor : #ffffff;
    --mainTabsColor: #ffffff;
    --LoveColor:#f4323d;
    --background-size: 160px;
    --Allcolor: #ffffff;
    --gamename:#ffffff;
    --unSubscribeColor: #ffffff;
    --gameMargin : 0;
    --colorActive : #fff;
    --profileTextColor : #fff;
    --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;

    --gameBgColor : #1b1f2a;
    --sidebardetailscolor: #ffffff;
    --loginLogoWidth : 160px;
    --navbarLogoWidth : 160px;
    --gameDetailsType : rgb(120, 120, 120);
    --borderRadiusSize : 22px;
    --tabMargin :0;
    --gameBorderB :'none';
    --gamePaddingB : 0;
    --gameMarginTop : 0;
    --gameBorderradius : 0;
    --linkGamemargin : 20px 3px 5px 15px;
    --imgBorder : 22%;
    --backgroundcat :#1a1f2a;
    --activeBackground: #1a1f2a;
    --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));

    --loginLogo: url(../assets/images/logos/sudan/zain-sd.svg);
    --navbarLogo: url(../assets/images/logos/sudan/zain-sd.svg);
    --leaderboard : url(../assets/images/leaderboard/bg.svg);
    --menuBg:url(../assets/icons/burger-menu.svg);
    --searchBg:url(../assets/icons/search.svg);

  }