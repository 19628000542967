    // .ncellTos {
    //   font-size: 13px;
    //   margin-top: 20px;
    // }
    // .links-Section{
    //   position: absolute;
    //     top: 30%;
    //     border: 1px solid red;
    // }
    // .gamezoneSignup
    // {
    //   background: rgb(238 90 17);
    // }
    .butoonRound {
        color: white;
        border: 3px solid #813700;
        box-shadow: 0px 2px 10px #813700;
        cursor: pointer;
        transition: all 0.2s ease;
        &.grow {
            transition: all .2s ease-in-out;
            &:hover {
                transform: scale(1.5);
            }
        }
    }
    
    .butoonRound:hover {
        transform: scale(1.1);
        /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
    
    .butoonRound:active {
        background-color: #48abe0;
        box-shadow: 0 0 2px darkslategray;
        transform: translateY(2px);
    }
    
    .butoonRound:not(:first-child) {
        margin-top: 10px;
    }
    
    .btn_du {
        background: #fff!important;
        // background: linear-gradient(135deg, rgba(171,46,181,1) 14%, rgba(1,170,206,1) 100%)!important;
        border: none!important;
        width: 100%!important;
        color: #0692de!important;
        height: 3.2em!important;
        border: 0!important;
        border-radius: 5em!important;
        font-size: 1.5em!important;
        font-weight: 700!important;
        display: block!important;
        text-transform: capitalize!important;
    }
    
    .du-cover {
        background: url("../../assets/images/background/eypbanner.png") no-repeat center center;
        // background: url("../../assets/images/mondia/");
    }
    
    .mondiacontent {
        color: #f8e250;
        width: 70%;
        margin: 0 auto;
        text-align: center;
        line-height: 1.2em;
        font-size: 1.4em;
        font-weight: 900;
        padding: .6em 1em;
    }
    
    .ducontent {
        text-transform: uppercase;
        line-height: 1.2em;
        color: #fff;
        font-size: 1.4em;
        font-weight: 600;
    }
    
    .jawwalgamezoridncellTos p {
        line-height: 20px;
        // background: url("../../assets/images/mondia/freegame.png");
    }
    
    .jawwalgamezoridFooter {
        margin-bottom: 0!important;
    }
    
    .mondiaoperatorSignup {
        background: url("../../assets/images/mondia/subscription-bg.jpg") no-repeat center center;
        background-size: cover;
        // background: url("../../../public/assets/images/Egypt/banner.svg");
    }
    
    .EgyoperatorSignup {
        background-color: #5C1877;
        opacity: 0.85;
        //  background: url("../../assets/images/Egypt/banner.svg");
    }
    
    .duFooter h4 {
        color: #f8f2f2!important;
        font-size: 1.5em!important;
        font-weight: inherit!important;
        margin-top: 0.5em!important;
    }
    
    .egy-info {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
    }
    
    .egy-info p {
        color: #fff!important;
        font-size: 0.85rem!important;
        width: 33%!important;
        text-align: left!important;
    }
    
    .du-info3 p {
        width: 100%!important;
    }
    
    .egy-info img {
        width: 1.8em;
        // margin-right: 0.4em;
        margin-left: 0!important;
    }
    
    .Egy-info {
        background-color: white;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin: auto;
        cursor: pointer;
    }
    
    .Egy-info img {
        width: 2.8em;
    }
    
    .EgyiconSelect {
        margin: 0;
    }
    
    .EgyiconSelect .select {
        width: 50%;
        border: 1px solid #fff;
        border-radius: 14px;
        padding-left: 12px!important;
    }
    
    .EgyiconSelect .select:focus {
        // color: red;
        // margin-right: 10px;
    }
    
    .content-row {
        justify-content: space-around;
    }
    
    .text-cond {
        color: #FD8C00!important;
        text-decoration: underline;
    }
    
    .egycontent {
        text-transform: capitalize;
    }

    .condtion-list
    {
        text-align: left;
        line-height: 18px;
        padding: 0 5px;
    }

    .condtion-list div
    {
        padding: 5px 30px 0;
        color: #fff;
    }

    .condtion-list div p
    {
        color: #fff!important;
    }

    .condtion-list li
    {

    }
.btn-egypt
    {
        width: 75%!important;
        background-color: #fff!important;
        color: #6a1d81!important;
        border: 2px solid #6a1d81!important;
        margin-top: 39px!important;
    }
    
    body[dir="rtl"] {
        .footerCondtion , .condtion-list {
            text-align: right!important;
        }
    }
    
    @media(max-width: 1024px) {
        .links-SectionIndonesia {
            width: 90%!important;
        }
    }
    
    @media(max-width: 540px) {
        .formsLayout .jawwalgamezoridFooter {
            margin-bottom: 10px!important;
        }
        .jawwalgamezoridncellTos p {
            //margin: 8px;
        }
        .btnmondia {
            width: 64%!important;
        }
        .links-Sectionjawwalgamezorid button {
            margin: 20px auto!important;
        }
    }
    
    @media(max-width: 418px) {
        //   .formsLayout .jawwalgamezoridFooter {
        //      margin-bottom: 0!important; 
        // }
        // .jawwalgamezoridncellTos p 
        // {
        //   line-height: 16px;
        // }
        .links-Sectionjawwalgamezorid button {
            margin: 40px auto!important;
        }
    }
    
    @media(max-width: 414px) {
        //   .formsLayout .jawwalgamezoridFooter {
        //      margin-bottom: 0!important; 
        // }
        .jawwalgamezoridncellTos p {
            line-height: 16px;
        }
        .links-Sectionjawwalgamezorid button {
            margin: 16px auto!important;
        }
    }
    
    @media(max-width: 411px) {
        .formsLayout .jawwalgamezoridFooter {
            margin-bottom: 0!important;
        }
        .jawwalgamezoridncellTos p {
            //margin: 10px;
        }
        .links-Sectionjawwalgamezorid button {
            margin: 40px auto!important;
        }
    }
    
    @media(max-width: 375px) {
        .formsLayout .jawwalgamezoridFooter {
            // margin-bottom: 70px!important; 
        }
        .jawwalgamezoridncellTos p {
            line-height: 16px;
        }
        .links-Sectionjawwalgamezorid button {
            margin: 25px auto!important;
        }
    }
    
    @media(max-width: 360px) {
        .formsLayout .jawwalgamezoridFooter {
            margin-bottom: 0!important;
        }
        .jawwalgamezoridncellTos p {
            margin: 8px;
            font-size: 10px!important;
            line-height: 15px;
        }
        .links-Sectionjawwalgamezorid button {
            margin: 20px auto!important;
        }
    }
    
    @media(max-width: 320px) {
        .formsLayout .jawwalgamezoridFooter {
            margin-bottom: 0!important;
        }
        .jawwalgamezoridncellTos p {
            margin: 0px;
            font-size: 10px!important;
        }
        .links-Sectionjawwalgamezorid button {
            margin: 10px auto!important;
        }
        .jawwalgames-info p {
            font-size: 12px;
        }
    }