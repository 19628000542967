
.headerwomenLogo
{
   // background: url("../../assets/images/valantine/logo1.png") no-repeat center center;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 150px;
    background-size: contain;
    margin-bottom: 10px;
}
.comments-women
{
    background: url("../../assets/images/women/Rectangle\ 1117.svg") no-repeat center center;
    width: 100%;
    height: 200px;
    background-size: contain;
  //  margin-top: 2vh;
    display: flex;
    align-items: center;

}
.comments-women form 
{
    width: 100%;
   margin: 0 auto;
   height: 100%;
}
.womensend
{
    background: url("../../assets/images/women/Rectangle\ 1116.svg") no-repeat center center;
    width: 130px;
    height: 45px;
    border: none;
    border-radius: 25px;
    color: white;
    text-transform: uppercase;
    outline: none;
}
.uploadimg
{
    background: url("../../assets/images/women/upload\ icon.svg") no-repeat center center;
    width: 130px;
    height: 87px;
    outline: none;
    border: none;
   // margin-top: 20px;

}
.fileupload
{
    color: #ff4172!important;
    font-size: 15px!important;
    font-weight: 800!important;
    border: 1px solid silver;
    padding: 12px;
    width: 50%!important;
    margin: 2px auto;
    border-radius: 12px;
    display: none;

}
.input-women
{
    margin: 17px 0;
    height: 80%;
}

.womenmodal
{
    background: url("../../assets/images/valantine/Mask\ Group\ 1.png") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;


}
.womenmodal p
{
    color: black;
}

.ncellTos-women
{
    margin-top: 90px!important;

}


.inputcheck
{
    margin: 0 5px;
}



@media(max-width: 320px){
    .wommen
    {
        margin-top: -9vh!important;
    }
  }