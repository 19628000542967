
.homeModel{
    background-image: url("../../assets/images/modelAds.jpg");
    background-size: 100% 100%;
    height: 100%;
    max-width: 341px;
}

.homeModel a{
    color: #ffffff00;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ff000000;
    padding: 7px;
    border-radius: 50%;
    width: 35px;
    height: 34px;
    
}