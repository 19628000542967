
.resend
{
    margin-top: 25px;
}

.verfycode
{
    margin-top: 150px;
}
.verfycode input
{
    width: calc(50% - 20px)!important;

}
.gamezroidmodal
{
    color: white;
    background-color: black;
    margin: 5%;
    padding: 7%;

}
  

.gamezroidmodal h3{
    
    background-color: white;
    color: black;
    padding: 19px;
    text-align: center;

}
.unsubSad
{
    padding: 40px 0!important;
    height : 0!important
}


